import React, { Component } from "react"

class Ariane extends Component {

  ariane = [];

  componentWillMount() {
    this.arianes = this.props.data
  }

  render() {
    return (
      <nav >
        <ul className="breadcrumb">
          <li className="breadcrumbItem">
            <a href="/" className="breadcrumbItemLink breadcrumbItemLinkHome"><i className="icon-mgc"></i></a>
          </li>
          {(this.arianes.map(ariane => {
            return <li className="breadcrumbItem" key={ariane.url}><a className="breadcrumbItemLink breadcrumbItemLinkMiddle" key={ariane.url} href={ariane.url}>{ariane.title}</a></li>
          })
          )}

        </ul>
      </nav>
    )
  }
}

export default Ariane
