import React, { Component } from "react"
import Blocks from "./blocks/blocks"
import Ariane from "./ariane"
import Layout from "../components/layout"
import Banner2QuestionsDesktop from "../images/cartouche1telOct2022.png"
import Banner1QuestionDesktop from "../images/cartouche1telOct2022.png"
import urlService from "../services/global/urlService"
import { ATInternetTagService } from './../services/global/ATInternetService';

class TemplateSimple extends Component {
  componentDidMount() {
    /*console.log("#####")
    console.log(this.props)*/
    ATInternetTagService.sendPageFromMetadata(this.props)
  }
  render() {
    const { pageContext: context } = this.props
    const { page } = context
    const { media } = context
    const titleSeo = page.titleSEO || ""
    const keywords = page.keywords ? page.keywords.split() : []
    const { tracker, canonicalUrl } = page
    const canonical = urlService.addSlashToEndOfUrl(canonicalUrl)

    let phoneCartridgeImage = null;
    switch (page.phoneCartridge) {
      case "UN_NUMERO":
        phoneCartridgeImage = (<img src={Banner1QuestionDesktop} alt="Cartouche téléphone 1" />)
      break;
      case "DEUX_NUMEROS":
        phoneCartridgeImage = (<img src={Banner2QuestionsDesktop} alt="Cartouche téléphone 2" />)
      break;
      default:
        break;
    } 

    return (
      <Layout
        titleSeo={titleSeo}
        keywordsSeo={keywords}
        description={page.description}
        tracker={tracker}
        canonical={canonical}
      >
        <section className="pageHeader">
          <div className="pageHeaderContainer">
            <Ariane data={page.ariane} />
            <div className="ciblePubDesktop">
              {phoneCartridgeImage}
            </div>
          </div>
        </section>
        {page.titleH1 && (
          <section className="pageHeader callback">
            <div>
              <h1 className="pageHeaderTitle">{page.titleH1}</h1>
            </div>
          </section>
        )}
        <div id="blocs">
          <Blocks data={page.blocks} media={media} title={page.title}/>
        </div>
      </Layout>
    )
  }
}

export default TemplateSimple
